import { memo } from 'react';
import { DropdownMenu } from '@declarando/design_system';
import { useRootContext } from 'shared/UI/context/RootContext/RootContext';
import RepositoryFactory from 'shared/infra/repositories/RepositoryFactory';
import { AppRouter } from 'routes/AppRouter';
import { PlanHelper } from '../../../stores/PlanHelper';
import { Planes_Codes } from '../../../domain/constants/Planes.js';

export const UserMenuDropdown = memo(({ history, navbarHeight }) => {
    const repositoryFactory = RepositoryFactory.instance;

    const routeRepository = repositoryFactory.getRoutesRepository();
    const { userIdentity, userInfo } = useRootContext();

    const planId = +userInfo.plan?.id;
    const options = [
        {
            titleTagOptions: [2, 3, 4].includes(planId)
                ? { text: 'Plan '.concat(PlanHelper.getVisualName(userInfo.plan?.id)), variant: 'info' }
                : planId === Planes_Codes.CODE_FREE
                  ? {
                        text: 'Periodo de prueba',
                        variant: 'neutral',
                    }
                  : undefined,
            title: `${userIdentity.firstName} ${userIdentity.lastName}`,
            subtitle: userIdentity.nif,
            children: 'Configuración del perfil',
            icon: 'setting',
            onClick: () => {
                history.push(routeRepository.getProfileUri());
            },
        },
        {
            icon: 'users-alt',
            onClick: () => {
                history.push(routeRepository.getAgentUri());
            },
            children: 'Tus clientes',
            isDisabled: !(+userInfo.isAgent === 1),
        },
        {
            children: 'Forma de pago',
            description: 'Datos de pago y facturas de Declarando',
            icon: 'credit-card',
            onClick: () => {
                history.push(routeRepository.getInvoicesListUri());
            },
        },
        {
            children: 'Tu plan de suscripción',
            icon: 'calendar-alt',
            onClick: () => {
                history.push(routeRepository.getPlansUri());
            },
        },
        {
            children: 'Invita a un amigo',
            description: 'Y gana un vale de 50€ de Amazon',
            divider: 'bottom',
            icon: 'gift',
            onClick: () => {
                history.push(routeRepository.getInviteFriend());
            },
        },
        /*{
            children: 'Ayuda y consejos',
            icon: 'question-circle',
            iconSecondary: 'external-link-alt',
            divider: 'bottom',
            onClick: () => {
                window.open(import.meta.env.VITE_FAQ_URL, '_blank');
            },
        },*/
        {
            children: 'Cerrar sesión',
            icon: 'sign-out-alt',
            onClick: AppRouter.handleLogout,
        },
    ].filter((item) => !item?.isDisabled);

    return (
        <>
            <DropdownMenu
                className={'profile'}
                triggerType="button"
                triggerProps={{ icon: 'user', variant: 'secondary', size: 'M', isIconOnly: true }}
                position="right"
                options={options}
                mobileTopPosition={navbarHeight}
            />
        </>
    );
});
