import {
    Box,
    FormControlContainer,
    Grid,
    Input,
    InputPhone,
    Link,
    Notification,
    Section,
    Select,
    SelectorGroup,
} from '@declarando/design_system';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { getInputProps } from 'shared/services/formulario/Formulario';
import { FormularioClienteHook } from './hooks/useFormularioCliente';
import { RootContext } from 'shared/UI/context/RootContext/RootContext';
import { ClienteService } from 'features/Incomes/services/ClienteService';
import { ClienteSchema } from 'features/Incomes/services/schemas/ClienteSchema';
import { useGetCountriesList } from 'features/Ingresos/Parameters/Locations/UI/hooks';
import { mapLegacyClientType } from 'features/Ingresos/Clientes/adapters/ClientAdapter';
import { ClientTypeChecks } from 'features/Ingresos/Clientes/domain/ClientType';
import { isCountryEU, isCountrySpain } from 'features/Ingresos/Parameters/Locations/services/CountryPredicate';
import { NifIvaOptions } from 'features/Incomes/UI/ModalCliente/form/DatosPersonalesCliente';
import Country from 'shared/UI/components/ListOptions/Country';

const NotificationCardInfo = ({ clientCountry, clientType }: { clientCountry: string; clientType: string }) => {
    const { countries } = useGetCountriesList();
    const filteredCountry = countries.find((country) => country.code === clientCountry);
    const transformedClientType = mapLegacyClientType(clientType);

    if (!filteredCountry) return null;

    if (
        !isCountryEU(filteredCountry) ||
        isCountrySpain(filteredCountry) ||
        !ClientTypeChecks.isCompanyOrFreelancer(transformedClientType)
    )
        return null;

    return (
        <Notification
            tone="info"
            title="Si el identificador corresponde con un NIF IVA válido, se aplicarán las reglas del IVA comunitario"
            message={
                <>
                    Al crear el ingreso con factura, podrás comprobar si el NIF IVA está inscrito en el VIES.
                    <Box style={{ lineHeight: '24px' }}>
                        <Link
                            href={
                                'https://ayuda.declarando.es/es/articles/2843194-que-es-el-roi?_ga=2.19180728.1860799966.1726475945-41921962.1718783164'
                            }
                            underline
                        >
                            Saber más
                        </Link>
                    </Box>
                </>
            }
        />
    );
};

export const DatosPersonalesCliente = ({ formularioCliente }: { formularioCliente: FormularioClienteHook }) => {
    const {
        form,
        setError,
        updatePhoneInput,
        iniInput,
        updateInput,
        updateInputProp,
        listaPoblaciones,
        listaProvincias,
    } = formularioCliente;

    const { userIdentity } = useContext(RootContext);

    const onChangeNifIva = (value: string) => {
        updateInput('nifIva', value);
    };

    const [codigosPostales, setListaCodigoPostales] = useState<any[]>([]);

    useEffect(() => {
        if (!form.cif.visible) {
            updateInput('cif', '');
        }
    }, [form.cif.visible]);

    useEffect(() => {
        updateInputProp(
            'direccion',
            'required',
            form.nifIva.value === NifIvaOptions.si || form.pais.value === Country.iso.SPAIN,
        );
    }, [form.nifIva.value]);

    useEffect(() => {
        if (listaPoblaciones.length && form.poblacion.value) {
            const findCodigosPostales =
                listaPoblaciones?.find((item) => +item.id === +form.poblacion.value)?.postalCodes ?? [];
            const codigosPostales = findCodigosPostales.map((cp) => ({ value: cp, label: cp }));
            setListaCodigoPostales(codigosPostales);
            const currentCP = form.cp.value || '';
            const includeCP = codigosPostales.find((item) => item.value === currentCP)?.value || '';
            iniInput('cp', codigosPostales.length === 1 ? codigosPostales[0].value : includeCP);
        }
    }, [setListaCodigoPostales, form.poblacion.value, form.cp.value, listaPoblaciones]);

    return (
        <Section>
            {form.nombre.visible && (
                <Input
                    {...getInputProps('nombre', form)}
                    id="nombre"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateInput('nombre', e.currentTarget.value)}
                />
            )}
            {form.nifIva.visible && (
                <FormControlContainer
                    required={form.nifIva.required}
                    label={form.nifIva.label}
                    error={Boolean(form.nifIva.error)}
                    message={form.nifIva.error}
                    info={
                        <>
                            El NIF-IVA o VAT es un código identificativo que permite realizar operaciones en la Unión
                            Europea y que se obtiene tras la inscripción en el ROI
                            <br />
                            <Link
                                href={'https://ayuda.declarando.es/es/articles/2843194-que-es-el-roi'}
                                styles={{ color: 'white', textDecoration: 'underline', display: 'block' }}
                            >
                                Saber más
                            </Link>
                        </>
                    }
                >
                    <Grid gridTemplateColumns={['100px 100px']}>
                        <SelectorGroup
                            id="client-nif-iva"
                            selectors={[
                                { label: 'Sí', id: NifIvaOptions.si, checked: form.nifIva.value === NifIvaOptions.si },
                                { label: 'No', id: NifIvaOptions.no, checked: form.nifIva.value === NifIvaOptions.no },
                            ]}
                            onChange={(selectedId) => onChangeNifIva(selectedId[0])}
                        />
                    </Grid>
                </FormControlContainer>
            )}
            {form.cif.visible && (
                <>
                    <Input
                        {...getInputProps('cif', form)}
                        id="cif"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateInput('cif', String(e.currentTarget.value).toUpperCase().trim(), false);
                        }}
                        onBlur={async () => {
                            const { cif } = form;
                            await updateInput('cif', cif.value);
                            const error = ClienteSchema.cif.validate(cif.value, !!cif.required, [userIdentity.nif]);
                            if (cif && !error) {
                                const { data: exist } = await new ClienteService().checkExitCif(cif.value);
                                setError('cif', exist ? 'Este identificador fiscal ya existe' : '');
                            }
                        }}
                    />
                    <NotificationCardInfo clientCountry={form.pais.value} clientType={form.type.value} />
                </>
            )}
            {form.provincia.visible && (
                <Grid gridTemplateColumns={{ _: '1fr', tablet: 'repeat(3, 1fr)' }} gridGap="sm">
                    <Select
                        {...getInputProps('provincia', form)}
                        id="provincia"
                        options={listaProvincias.map((provincia) => ({ label: provincia.name, value: provincia.id }))}
                        onChange={(value: string) => {
                            updateInput('provincia', value);
                            if ([38, 35].includes(+value)) {
                                updateInput('isCanario', true);
                            } else {
                                updateInput('isCanario', false);
                            }

                            if (+form.provincia.value !== +value) {
                                iniInput('poblacion', '');
                                iniInput('cp', '');
                                updateInputProp('cp', 'error', '');
                                setListaCodigoPostales([]);
                            }
                        }}
                    />
                    <Select
                        {...getInputProps('poblacion', form)}
                        id="poblacion"
                        options={listaPoblaciones.map((poblacion) => ({ label: poblacion.name, value: poblacion.id }))}
                        onChange={(value: string) => {
                            updateInput('poblacion', value);
                            if (+form.poblacion.value !== +value) {
                                iniInput('cp', '');
                                updateInputProp('cp', 'error', '');
                            }
                        }}
                    />
                    {codigosPostales.length >= 1 &&
                        (codigosPostales.length > 1 ? (
                            <Select
                                {...getInputProps('cp', form)}
                                id="cp"
                                options={codigosPostales}
                                onChange={(value: string) => updateInput('cp', value)}
                            />
                        ) : (
                            <Input
                                {...getInputProps('cp', form)}
                                id="cp"
                                inform
                                value={codigosPostales[0].value}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    updateInput('cp', e.currentTarget.value)
                                }
                            />
                        ))}
                </Grid>
            )}

            {form.direccion.visible && (
                <Input
                    {...getInputProps('direccion', form)}
                    id="direccion"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateInput('direccion', e.currentTarget.value)}
                />
            )}
            <Grid gridTemplateColumns={{ _: '1fr', tablet: '1fr 1fr' }} gridColumnGap="sm" gridRowGap="sm">
                {form.phone.visible && (
                    <InputPhone
                        id={'phone'}
                        label={form.phone.label}
                        className="input-phone"
                        placeholder="Introduce el teléfono de tu cliente"
                        prefixValue={form.phone.value.prefix}
                        numberValue={form.phone.value.number}
                        onChangePhonenumber={(phone: string) => updatePhoneInput('phone', 'number', phone, false)}
                        onChangePrefixPhone={(prefijo: any) => updatePhoneInput('phone', 'prefix', prefijo, false)}
                        error={Boolean(form.phone.error)}
                        message={form.phone.error}
                        required={form.phone.required}
                        onBlur={() => {
                            updatePhoneInput('phone', 'prefix', form.phone.value.prefix);
                            updatePhoneInput('phone', 'number', form.phone.value.number);
                        }}
                    />
                )}

                {form.email.visible && (
                    <Input
                        type="email"
                        id="email"
                        {...getInputProps('email', form)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            updateInput('email', e.currentTarget.value, false)
                        }
                        onBlur={() => {
                            updateInput('email', form.email.value || null);
                        }}
                    />
                )}
            </Grid>
        </Section>
    );
};
